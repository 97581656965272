import React, {
  useCallback,
  useEffect,
  useMemo,
  FunctionComponent,
  useState
} from 'react';
import store from '~/config/store';
import { constructPrefetchArguments } from '~/helpers/router';
import { PrefetchProps } from '~/server/prefetchData';
import { isOnServer } from '~/helpers/isOnServer';
import { Store } from 'redux';
import { ReduxStore } from '~/App/shared/interfaces/store';
import { TranslateFunction, useTranslation } from '~/Locale';
import { RouteConfig } from '~/config/routes/types';
import { log } from '~/helpers/bugsnagHelper';
import { ServerInitialData } from '~/globalTypes';
import { RouteComponentProps } from '~/App/shared/interfaces/routes';
import { areDeepEquals } from '~/App/shared/utils';

/*
 * This is a Higer order component that is used to decorate Components with a
 * data fetching function. That function is then called in one of 3 cases:
 * 1 -> A client-side navigation occurs to a location where
 *      the view is wrapped with this wrapper (Done in the AsyncRouter component)
 * 2 -> On client-side when dataHookFunction cannot be located in AsyncRouter component.
 *      Could happen if component is nested (Done in the ClientSideComponent useEffect)
 * 3 -> This component is rendered on the server
 *      (Done in src/server/prefetchData using react-ssr-prepass)
 *
 * This component also handles injection of preloaded data, either on the
 * server or on the client.
 *
 * The data returned from the function that is passed as the `dataHookFunction`
 * to this function will be set as props to the wrapped component.
 * for example:
 * provideDataHook('someIdentifier', () => { return { blah: 123 } })(AComponent);
 * this would supply the `blah` prop to AComponent with the value 123
 *
 * In some cases dataHookFunction does not return data - data could be stored in
 * redux and is not passed to wrapped component.
 *
 * */

function areObjectsEqual(obj1?: object, obj2?: object): boolean {
  if (!obj1 || !obj2) {
    return false;
  }
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const setPreloadedDataGlobal = (hash: string | number, data: object) => {
  window._INITIAL_DATA_ = {
    ...window._INITIAL_DATA_,
    prefetchedRequests: {
      ...window?._INITIAL_DATA_?.prefetchedRequests,
      [hash]: data
    }
  };
};

const getPreloadedData = (hash: string) => {
  const data = window?._INITIAL_DATA_?.prefetchedRequests?.[hash];

  // check if data is of an object type
  if (data && typeof data === 'object') {
    return data;
  }

  return undefined;
};

export type DataHookFunctionProps<
  Params extends { [K in keyof Params]?: string } = object,
  ExtraProps = object
> = Store<ReduxStore> &
  ExtraProps &
  RouteComponentProps<object, Params> & {
    params: Params & { splat?: string };
    routes?: RouteConfig[];
    t: TranslateFunction;
  };

export type DataHookFunctionPropsWithoutT = Omit<DataHookFunctionProps, 't'>;

type Options = {
  isNestedComponent?: boolean;
  skipSpinner?: boolean;
};

export type StaticComponentProps<T = Record<string, unknown>> = {
  uniqueIdentifier: string;
  skipSpinner: boolean;
  preRenderFetch: (dataHookProps: DataHookFunctionProps) => Promise<T | void>;
  displayName: string;
};

const provideDataHook =
  <T1 extends object>(
    uniqueIdentifier: string,
    dataHookFunction: (
      dataHookProps: DataHookFunctionProps
    ) => Promise<T1 | void> | void,
    options?: Options
  ) =>
  <T2 extends RouteComponentProps>(
    Component: React.ComponentType<T2>
  ): FunctionComponent<Omit<T2, keyof T1>> & StaticComponentProps<T1> => {
    if (!uniqueIdentifier || typeof uniqueIdentifier !== 'string') {
      throw 'You must supply a unique identifier as the first argument';
    }

    if (typeof dataHookFunction !== 'function') {
      throw 'The data hook function must be a function';
    }

    const ClientSideComponent = ({ ...props }: T2) => {
      const { t } = useTranslation();
      const [preFetchHelper, setPreFetchHelper] = useState({
        fetching: false,
        fetched: false
      });
      const storedPreloadedData = getPreloadedData(uniqueIdentifier);
      const [preloadedData, setPreloadedData] = useState(storedPreloadedData);
      const { isNestedComponent = false } = options ?? {};

      const params = useMemo(
        () =>
          constructPrefetchArguments({
            props: props as PrefetchProps,
            store
          }) as DataHookFunctionPropsWithoutT,
        [props]
      );

      const preRenderFetch = useCallback(
        async (dataHookProps: DataHookFunctionProps) => {
          return await dataHookFunction(dataHookProps);
        },
        []
      );

      useEffect(() => {
        if (
          isNestedComponent &&
          !preFetchHelper.fetching &&
          !preFetchHelper.fetched
        ) {
          setPreFetchHelper({ fetching: true, fetched: false });

          preRenderFetch({ ...params, t })
            .then(data => {
              if (
                data &&
                typeof data === 'object' &&
                !areObjectsEqual(storedPreloadedData, data)
              ) {
                setPreloadedDataGlobal(uniqueIdentifier, data);
                setPreloadedData(data);
              }
            })
            .catch(log)
            .finally(() => {
              setPreFetchHelper({ fetching: false, fetched: true });
            });
        }
      }, [
        storedPreloadedData,
        isNestedComponent,
        preRenderFetch,
        params,
        preFetchHelper,
        t
      ]);

      // on CSR preRenderFetch can also be called from src/App/components/AsyncRouter
      // update preloadedData in state if stored value be changed
      useEffect(() => {
        if (!areDeepEquals(storedPreloadedData, preloadedData)) {
          setPreloadedData(storedPreloadedData);
        }
      }, [storedPreloadedData, preloadedData]);

      return <Component {...props} {...preloadedData} />;
    };

    const ServerSideComponent = ({ ...props }: T2) => {
      const reqId = props?.location?.reqId;

      if (!reqId) {
        throw Error('reqId must be provided in application file!');
      }

      const prefetchedRequests = (global?._INITIAL_DATA_ as ServerInitialData)
        ?.prefetchedRequests?.[reqId];

      const ssrDataHookProcessed = prefetchedRequests?.ssrDataHookProcessed;
      const prefetchedData = prefetchedRequests?.[uniqueIdentifier];

      if (prefetchedData && typeof prefetchedData === 'object') {
        return (
          <Component
            {...props}
            {...(prefetchedData ?? {})}
            ssrDataHookProcessed={ssrDataHookProcessed}
          />
        );
      }

      return (
        <Component {...props} ssrDataHookProcessed={ssrDataHookProcessed} />
      );
    };

    const ComponentWithStaticProps = (
      isOnServer ? ServerSideComponent : ClientSideComponent
    ) as FunctionComponent<Omit<T2, keyof T1>> & StaticComponentProps<T1>;

    ComponentWithStaticProps.uniqueIdentifier = uniqueIdentifier;
    ComponentWithStaticProps.skipSpinner = options?.skipSpinner ?? false;

    ComponentWithStaticProps.preRenderFetch = async (
      dataHookProps: DataHookFunctionProps
    ) => await dataHookFunction(dataHookProps);

    ComponentWithStaticProps.displayName = `DataHook${uniqueIdentifier}`;

    return ComponentWithStaticProps;
  };

export default provideDataHook;
