import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { translateRoute } from '~/App/shared/localization';
import Link from '~/App/shared/components/Link';
import { useTranslation } from '~/Locale';
import { useHistory } from 'react-router';
import NotFoundGraphic from '~/App/shared/graphics/NotFoundGraphic';
import { mq, SearchIcon, TextField } from '@kvdbil/components';
import { trackGA4PageNotFound } from '~/helpers/client/ga4TrackEvent';
import { RouteComponentProps } from '../interfaces/routes';

const Container = styled.div`
  padding: 50px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray.light1};
  margin: 0 auto;
  max-width: 300px;

  ${mq('tablet')} {
    max-width: 400px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 10em;
`;

const Message = styled.p`
  margin-bottom: 2rem;
`;

const Search = styled.div`
  margin-bottom: 2rem;
`;

const LinkWrapper = styled.div`
  margin-bottom: 2rem;
`;

const StartLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray.light1};
  font-weight: ${({ theme }) => theme.typography.fontBaseBoldWeight};
  text-decoration: underline;
`;

const NotFound = ({
  staticContext
}: Pick<RouteComponentProps, 'staticContext'>) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push(
      `${translateRoute(t, '/ROUTES.AUCTIONS')}?terms=${searchText}`
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  useEffect(() => {
    trackGA4PageNotFound();
  }, []);

  return (
    <Container data-testid="not-found">
      <ImageContainer>
        <NotFoundGraphic data-testid="not-found-graphic" />
      </ImageContainer>
      <Message>
        {t(
          'The page you are looking for seems to be removed or you typed an url that dont exist.'
        )}
      </Message>
      <Search>
        <form action="" onSubmit={handleSearchSubmit}>
          <TextField
            value={searchText}
            onChange={handleChange}
            label={t('Search vehicle')}
            suffix={<SearchIcon />}
          />
        </form>
      </Search>
      <LinkWrapper>
        <StartLink to="/">{t('Go to startpage')}</StartLink>
      </LinkWrapper>
    </Container>
  );
};

export default NotFound;
