import { Facility } from '~/App/shared/interfaces/Facility';

export interface MetaItem {
  name?: string;
  property?: string;
  content: string;
}

export const getPageTitleFromMeta = (metaList?: MetaItem[]) => {
  if (!Array.isArray(metaList) || !metaList.length) {
    return '';
  }

  const metaTitle = metaList.find(
    metaObject => metaObject.property === 'og:title'
  );

  return metaTitle?.content ?? '';
};

export const addCarTreeMetaDescription = (
  metaList: MetaItem[],
  description: string
) => {
  if (!Array.isArray(metaList) || !metaList.length) {
    metaList.push({
      name: 'description',
      content: description
    });

    return metaList;
  }

  metaList.forEach(metaItem => {
    const name = metaItem?.name;

    if (!(name === 'description')) {
      metaList.push({
        name: 'description',
        content: description
      });
    }

    if (name === 'description') {
      metaItem.content = description;
    }
  });

  return metaList;
};

export const mergeFacilityData = (
  pageFacility: Pick<Facility, 'latitude' | 'longitude'>,
  responseFacility: Facility
) => {
  const coords = {
    latitude: responseFacility.latitude || pageFacility.latitude,
    longitude: responseFacility.longitude || pageFacility.longitude
  };
  return { ...responseFacility, ...coords };
};

const blockedExtensions = ['json', 'js'];
export const isValidSlug = (slug: string) => {
  return !blockedExtensions.some(extension => slug.endsWith(`.${extension}`));
};
